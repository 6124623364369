import { useCallback, useReducer } from 'react';

import { useResources } from '@personly/libs-providers';

const initialState = {
  id: '',
  name: '',
  redirectUrisList: [''],
  scopesList: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CLIENT_FETCHED': {
      return action.data;
    }

    default:
      return state;
  }
};

const useClients = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const resources = useResources();

  const getClient = useCallback(
    (id) =>
      resources(async ({ clients, messages }) => {
        const clientsMessages = await messages.clients;
        const client = await clients.clients;

        const request = new clientsMessages.GetClientRequest();
        request.setId(id);

        const response = await client.getClient(request);
        const { result } = response.toObject();

        dispatch({ type: 'CLIENT_FETCHED', data: result });
      }),
    [resources]
  );

  return { client: state, getClient };
};

export default useClients;

import { useCallback } from 'react';

import { useResources } from '@personly/libs-providers';

const useEmailAddresses = () => {
  const resources = useResources();

  const verifyEmailAddress = useCallback(
    (token, cb) =>
      resources(async ({ clients, messages }) => {
        const emailAddresses = await messages.emailAddresses;
        const client = await clients.emailAddresses;

        const request = new emailAddresses.VerifyEmailAddressRequest();
        request.setToken(token);

        const response = await client.verifyEmailAddress(request);
        const { result } = response.toObject();

        cb(result);
      }),
    [resources]
  );

  return { verifyEmailAddress };
};

export default useEmailAddresses;

import { useCallback, useReducer } from 'react';

import { useResources } from '@personly/libs-providers';

const initialState = {
  price: {
    base: { assetHandle: '', unitHandle: '' },
    quote: { assetHandle: '', unitHandle: '' },
    value: 0,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'PRICE_FETCHED': {
      return { ...state, price: { ...state.price, ...action.data } };
    }

    default:
      return state;
  }
};

const usePrices = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const resources = useResources();

  const getPrice = useCallback(
    () =>
      resources(async ({ clients, messages, metadata }) => {
        const prices = await messages.prices;
        const client = await clients.prices;

        const request = new prices.GetPriceRequest();

        const response = await client.getPrice(request, metadata);
        const { result } = response.toObject();

        dispatch({ type: 'PRICE_FETCHED', data: result });
      }),
    [resources]
  );

  return { getPrice, price: state.price };
};

export default usePrices;

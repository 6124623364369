import { useCallback, useReducer } from 'react';

import { useResources } from '@personly/libs-providers';

const initialState = {
  data: {
    REGISTRATION_PROMO: {
      quantity: { measure: { assetHandle: '', unitHandle: '' }, value: 0 },
    },
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'COUPONS_FETCHED': {
      const data = action.data.reduce(
        (acc, cur) => ({ ...acc, [cur.id]: cur.quantity }),
        {}
      );

      return { ...state, data };
    }

    default:
      return state;
  }
};

const useCoupons = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const resources = useResources();

  const getCoupons = useCallback(
    () =>
      resources(async ({ clients, messages, metadata }) => {
        const coupons = await messages.coupons;
        const client = await clients.coupons;

        const request = new coupons.GetCouponsRequest();

        const response = await client.getCoupons(request, metadata);
        const { resultList } = response.toObject();

        dispatch({ type: 'COUPONS_FETCHED', data: resultList });
      }),
    [resources]
  );

  return { getCoupons, coupons: state.data };
};

export default useCoupons;

import { useCallback, useReducer } from 'react';

import { useResources } from '@personly/libs-providers';

const initialState = {
  personalCity: {
    name: '',
    id: '',
    provinceName: '',
    countryName: '',
    location: null,
  },
  data: [],
  personalPermissions: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'PERSONAL_CITIES_FETCHED': {
      return { ...state, data: action.data };
    }

    case 'PERSONAL_CITIES_CLEARED': {
      return { ...state, data: [] };
    }

    case 'PERSONAL_CITY_FETCHED': {
      return { ...state, personalCity: { ...action.data } };
    }

    case 'PERSONAL_CITY_CLEARED': {
      return {
        ...state,
        personalCity: { ...initialState.personalCity },
      };
    }

    case 'PERSONAL_PERMISSIONS_FETCHED': {
      return { ...state, personalPermissions: [...action.data] };
    }

    default:
      return state;
  }
};

const usePersonals = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const resources = useResources();

  const searchPersonalCities = useCallback(
    (search, languageHandle) =>
      resources(async ({ clients, messages, metadata }) => {
        const personals = await messages.personals;
        const client = await clients.personals;

        const request = new personals.SearchPersonalCitiesRequest();
        request.setSearch(search);
        request.setLanguageHandle(languageHandle);

        const response = await client.searchPersonalCities(request, metadata);
        const { resultList } = response.toObject();

        dispatch({ type: 'PERSONAL_CITIES_FETCHED', data: resultList });
      }),
    [resources]
  );

  const clearPersonalCities = useCallback(() => {
    dispatch({ type: 'PERSONAL_CITIES_CLEARED' });
  }, []);

  const getPersonalCity = useCallback(
    (id) =>
      resources(async ({ clients, messages, metadata }) => {
        const personals = await messages.personals;
        const client = await clients.personals;

        const request = new personals.GetPersonalCityRequest();
        request.setId(id);

        const response = await client.getPersonalCity(request, metadata);
        const { result } = response.toObject();

        dispatch({ type: 'PERSONAL_CITY_FETCHED', data: result });
      }),
    [resources]
  );

  const clearPersonalCity = useCallback(() => {
    dispatch({ type: 'PERSONAL_CITY_CLEARED' });
  }, []);

  const getPersonalPermissions = useCallback(
    () =>
      resources(async ({ clients, messages, metadata }) => {
        const personals = await messages.personals;
        const client = await clients.personals;

        const request = new personals.GetPersonalPermissionTreeRequest();

        const response = await client.getPersonalPermissionTree(
          request,
          metadata
        );
        const { resultList } = response.toObject();

        dispatch({ type: 'PERSONAL_PERMISSIONS_FETCHED', data: resultList });
      }),
    [resources]
  );

  return {
    personalCity: state.personalCity,
    personalCities: state.data,
    personalPermissions: state.personalPermissions,
    getPersonalCity,
    clearPersonalCity,
    clearPersonalCities,
    searchPersonalCities,
    getPersonalPermissions,
  };
};

export default usePersonals;

import { useCallback } from 'react';

import { useResources } from '@personly/libs-providers';

const usePasswords = () => {
  const resources = useResources();

  const forgotPassword = useCallback(
    ({ email, solution }, cb) =>
      resources(
        async ({ clients, messages }) => {
          const client = await clients.passwords;
          const passwords = await messages.passwords;

          const request = new passwords.ForgotPasswordRequest();
          request.setSolution(solution);
          request.setEmail(email);

          const response = await client.forgotPassword(request);
          const { result } = response.toObject();

          cb(result);
        },
        ['FORGOT_PASSWORD']
      ),
    [resources]
  );

  const resetPassword = useCallback(
    ({ password, token }, cb) =>
      resources(
        async ({ clients, messages }) => {
          const passwords = await messages.passwords;
          const client = await clients.passwords;

          const request = new passwords.ResetPasswordRequest();
          request.setPassword(password);
          request.setToken(token);

          const response = await client.resetPassword(request);
          const { result } = response.toObject();

          cb(result);
        },
        ['RESET_PASSWORD']
      ),
    [resources]
  );

  return { forgotPassword, resetPassword };
};

export default usePasswords;

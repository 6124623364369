'use client';

import { useMemo } from 'react';

const useOAuth = (searchParams, client) => {
  const responseType = useMemo(() => {
    if (!searchParams.has('response_type')) return 'code';
    return searchParams.get('response_type');
  }, [searchParams]);

  const state = useMemo(() => {
    if (!searchParams.has('state')) return '';
    return searchParams.get('state');
  }, [searchParams]);

  const redirectUri = useMemo(() => {
    if (!searchParams.has('redirect_uri')) return client.redirectUrisList[0];
    return searchParams.get('redirect_uri');
  }, [searchParams, client.redirectUrisList]);

  const scope = useMemo(() => {
    if (!searchParams.has('scope')) return client.scopesList.join('');
    return searchParams.get('scope');
  }, [searchParams, client.scopesList]);

  return { responseType, scope, state, redirectUri };
};

export default useOAuth;
